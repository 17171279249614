<template>
  <div>
    <div
      class="search-wrap"
      style="position: absolute; right: 10px; margin-top: -87px"
    >
      <div class="sort">
        <v-menu
          v-if="config.sortFields != null && config.sortFields.length > 0"
          style="max-width: 300px"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <a v-on="on">{{
              getSortField() != null ? getSortField().title : 'выберите поле'
            }}</a>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-list-item-group mandatory color="primary">
                <v-list-item
                  v-for="(field, fieldIndex) in config.sortFields"
                  :key="fieldIndex + 'sortitems'"
                >
                  <v-list-item-content
                    @click="
                      sortField = field.path;
                      reload();
                    "
                  >
                    <v-list-item-title
                      >Сортировать по {{ field.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn
          x-small
          outlined
          @click="
            sortAsc = !sortAsc;
            reload();
          "
          v-bind:class="{ sortDesk: sortAsc }"
          v-if="config.sortFields != null && config.sortFields.length > 0"
        >
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.16667 -1.42833e-07L4.16667 11.8083L1.175 8.825L-2.7869e-07 10L5 15L10 10L8.825 8.825L5.83333 11.8083L5.83333 -1.99966e-07L4.16667 -1.42833e-07Z"
              fill="#6759D6"
            />
          </svg>
        </v-btn>
        <v-btn @click="filtersOpened = !filtersOpened"
          >Поиск
          <v-icon v-if="!filtersOpened">mdi-magnify</v-icon>
          <v-icon v-if="filtersOpened">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-btn outlined rounded class="download"></v-btn>
    </div>
    <Transition name="fade" mode="out-in">
      <form
        class="list-filters"
        v-if="filtersOpened"
        @submit.prevent="reload()"
      >
        <div>
          <template v-for="(filter, filterIndex) in config.filters">
            <v-text-field
              v-if="filter.type === 'STRING'"
              clearable
              filled
              dense
              :label="filter.title"
              v-model="filters[filter.path]"
              :key="filterIndex + 'filterindex'"
            ></v-text-field>
            <v-autocomplete
              v-if="filter.type === 'DICT'"
              clearable
              filled
              dense
              :label="filter.title"
              :key="filterIndex + 'filterindex'"
              v-model="filters[filter.path]"
              :items="filter.dict"
              item-value="code"
              item-text="title"
            ></v-autocomplete>
            <template v-if="filter.type === 'DATE-RANGE'">
              <div :key="filterIndex" class="date-range">
                <label>{{ filter.title }}:</label>
                <date-field
                  label="с"
                  :key="filterIndex + 'filterindexr1'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_more'] = $event"
                  :value="filters[filter.path + '_more']"
                >
                </date-field>
                <date-field
                  label="по"
                  :key="filterIndex + 'filterindexr2'"
                  min-date="2000-01-01"
                  max-date="2029-12-31"
                  @input="filters[filter.path + '_less'] = $event"
                  :value="filters[filter.path + '_less']"
                >
                </date-field>
              </div>
            </template>
            <template v-if="filter.type === 'NUMBER-RANGE'">
              <div :key="filterIndex" class="number-range">
                <label>{{ filter.title }}:</label>
                <v-text-field
                  label="от"
                  :key="filterIndex + 'filterindexr1'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_more']"
                ></v-text-field>
                <v-text-field
                  label="до"
                  :key="filterIndex + 'filterindexr2'"
                  clearable
                  filled
                  dense
                  v-model="filters[filter.path + '_less']"
                ></v-text-field>
              </div>
            </template>
            <template v-if="filter.type === 'REGISTER'">
              <register-select-field
                :key="filterIndex + 'filterindex'"
                :field-title="filter.title"
                :dialog-title="filter.dialogTitle"
                :reg-config="filter.regConfig"
                page-size="10"
                default-tab="0"
                :text-function="
                  (item) => (item != null ? item[filter.itemText] : '')
                "
                :text-search-attribute="filter.itemText"
                :value-object="registerValueObjects[filter.path]"
                @select="
                  filters[filter.path] = $event[filter.itemValue];
                  registerValueObjects[filter.path] = $event;
                  $forceUpdate();
                "
                @clear="
                  delete filters[filter.name];
                  $forceUpdate();
                "
              >
              </register-select-field>
            </template>
          </template>
        </div>

        <div>
          <v-btn
            outlined
            @click="
              filters = {};
              reload();
            "
          >
            Сбросить все
          </v-btn>
          <v-btn @click="reload(); pushUrl()"> Применить </v-btn>
          <input type="submit" hidden />
        </div>
      </form>
    </Transition>
    <section v-if="apiLoaded">
      <div class="text-block" v-if="notDecryptedCount">
        Количество нерасшифрованных проектов: <b>{{ notDecryptedCount }}</b>
      </div>
      <v-btn type="button" @click="changeKey" class="text-block" v-if="notDecryptedCount" >
        Изменить ключ шифрования
      </v-btn>
      <div v-if="!entries.length">
        Не найдены результаты, удовлетворяющие запросу
      </div>
      <ul class="register-list">
        <li
          v-for="(entry, entryIndex) in entries"
          :key="entryIndex + 'counterpart'"
        >
          <slot
            :offer-experts="offerExperts"
            :entry="entry"
            :passport="wD[entry.id]"
            :select="selectEntry"
            :select-mode="selectMode"
          >
          </slot>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import api from '@/modules/api';
import DateField from '../elements/DateField.vue';
import RegisterSelectField from './RegisterSelectField';
import { projectKeyHolderNew } from '@/modules/KeysHolder.js';
import { loadDataToObject } from '@/modules/CommonUtils';
import { wDec, wGet } from '@/modules/Wirings';
import { projectsRegisterConfig } from '@/components/register/RegisterConfigs';

import {
  finPassport,
  calculatePassport,
  projectsSumsReset,
} from '@/components/register/WireDict';

export default {
  name: 'RegisterTab',
  //конфиг - джсон с конфигурацией табы реестра, pickMode - запускаем ли в режиме выбора записи, presets - предустановленные фильтры
  props: ['selectMode', 'year', 'projectsSums', 'entryComponent'],
  components: {
    DateField,
    RegisterSelectField,
  },
  data() {
    return {
      config: projectsRegisterConfig,
      filtersOpened: false,
      sortAsc: true,
      sortField: '',
      filters: {},
      registerValueObjects: {}, //хранилище для объектов-значений фильтров типа REGISTER
      entries: [], //загруженные записи реестра
      entriesStats: {},
      wD: {},
      apiLoaded: false,
      notDecryptedCount: 0
    };
  },
  watch: {
    async selectedYear() {
      await this.reload();
      await this.finStatsUpdate();
      await this.projectsSumsUpdate();
    }
  },
  computed: {
    selectedYear() {
      return this.year || 2023;
    }
  },
  methods: {
    updateUrl() {
      if(this.filters['offerExpert.id']) {
        this.filters['offerExpert.id'] = parseInt(this.filters['offerExpert.id'])
      }
    },
    pushUrl() {
      const query = {
        ...this.filters,
        sortField: this.sortField,
        sortAsc: this.sortAsc
      };
      if (this.$route.path !== query || JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.push({ query });
      }
    },

    applyFiltersFromUrl() {
      const { sortField, sortAsc, ...filters } = this.$route.query;
      this.filters = filters;
      if (sortField) this.sortField = sortField;
      if (sortAsc) this.sortAsc = sortAsc === 'true';
    },

    loadData: loadDataToObject,
    selectEntry($event) {
      this.$emit('select', $event)
    },
    finPassport: finPassport,
    calculatePassport: calculatePassport,
    projectsSumsReset: projectsSumsReset,
    async reload() {
      this.entries = [];
      this.updateUrl();
      await this.loadPage();
    },
    projectsSumsUpdate() {
      if (Object.values(this.wD).length > 0 && this.projectsSums) {
        this.projectsSums = projectsSumsReset(this.projectsSums);
        Object.values(this.wD).forEach((value) => {
          if (value.data.length > 0) {
            this.projectsSums.contractSums.plan += +value.sum.contractSum.plan;
            this.projectsSums.contractSums.fact += +value.sum.contractSum.fact;
            this.projectsSums.viContractSums.plan +=
              +value.sum.viContractSum.plan;
            this.projectsSums.viContractSums.fact +=
              +value.sum.viContractSum.fact;
            this.projectsSums.hiContractSums.plan +=
              +value.sum.hiContractSum.plan;
            this.projectsSums.hiContractSums.fact +=
              +value.sum.hiContractSum.fact;
            this.projectsSums.priProjectProfitSums.plan +=
              +value.sum.priProjectProfit.plan;
            this.projectsSums.priProjectProfitSums.fact +=
              +value.sum.priProjectProfit.fact;
          }
        });
      }
    },
    //задан ли фильтр с path
    isFilterSet(path) {
      return (
        this.getFilterValue(path) != null &&
        this.getFilterValue(path).toString().length > 0
      );
    },

    //получить значение фильтра с path
    getFilterValue(path) {
      return this.filters[path];
    },

    //загрузить следующую страницу реестра
    async loadPage() {
      let portion = (await api.get(this.config.apiRestEndpoint + this.selectedYear))
        .payload
      for (let i = 0; i < portion.length; i++) {
        let decrypted = await projectKeyHolderNew.decode(
          portion[i].data
        );
        if(!decrypted) continue;
        decrypted.decrypted = true
        decrypted.id = portion[i].id;
        decrypted.number = portion[i].year + '-' + portion[i].numberInYear
        portion[i] = decrypted;
      }
      const decryptedPortion = portion.filter((p) => p.decrypted)
      this.notDecryptedCount = portion.length - decryptedPortion.length
      this.entries = decryptedPortion.filter((e) => {
        if (this.isFilterSet('fullName'))
          if (
            !e?.fullName
              .toUpperCase()
              .includes(this.getFilterValue('fullName').toUpperCase())
          )
            return false;
        if (this.isFilterSet('status'))
          if (e?.status !== this.getFilterValue('status')) return false;
        if (this.isFilterSet('offerExpert.id'))
          if (e.offerExpert !== this.getFilterValue('offerExpert.id'))
            return false;
        if (this.isFilterSet('market'))
          if (!e.markets || !e.markets.includes(this.getFilterValue('market')))
            return false;
        return true;
      });
      this.entries.sort((a, b) => {
        switch (this.sortField) {
          case 'id':
            return (this.sortAsc ? 1 : -1) * (a.id - b.id);
          case 'number':
            return (this.sortAsc ? 1 : -1) * (a.number > b.number ? 1 : -1);
          case 'status':
            return (this.sortAsc ? 1 : -1) * (a.status > b.status ? 1 : -1);
          case 'offerExpert.fullName':
            return (
              (this.sortAsc ? 1 : -1) *
              (a.offerExpert?.fullName > b?.offerExpert.fullName ? 1 : -1)
            );
        }
      });
    },
    finStatsUpdate() {
      this.wD = {};
      if (this.entries.length > 0) {
        this.entries.forEach((item) => {
          // Инициализируем объект для записи данных
          this.wD[item.id] = { sum: {}, data: [] };
          this.projectWInfo.forEach((wItem) => {
            if (wItem?.data?.project?.id == item.id) {
              this.wD[item.id].data.push(wItem);
            }
          });
          if (this.wD[item.id].data.length > 0) {
            this.wD[item.id].data.forEach((itemW) => {
              // Расчет финансовых показателей
              this.wD[item.id].sum = finPassport(itemW, this.wD[item.id].sum);
            });
            this.wD[item.id].sum = calculatePassport(this.wD[item.id].sum);
          }
        });
      }

      // Обновление сумм по проектам
      if (Object.values(this.wD).length > 0 && this.projectsSums) {
        this.projectsSums = projectsSumsReset(this.projectsSums);
        console.log('wD length', Object.values(this.wD));
        Object.values(this.wD).forEach((value) => {
          if (value.data.length > 0) {
            this.projectsSums.contractSums.plan += +value.sum.contractSum.plan;
            this.projectsSums.contractSums.fact += +value.sum.contractSum.fact;
            this.projectsSums.viContractSums.plan +=
              +value.sum.viContractSum.plan;
            this.projectsSums.viContractSums.fact +=
              +value.sum.viContractSum.fact;
            this.projectsSums.hiContractSums.plan +=
              +value.sum.hiContractSum.plan;
            this.projectsSums.hiContractSums.fact +=
              +value.sum.hiContractSum.fact;
            this.projectsSums.priProjectProfitSums.plan +=
              +value.sum.priProjectProfit.plan;
            this.projectsSums.priProjectProfitSums.fact +=
              +value.sum.priProjectProfit.fact;
          }
        });
      }
    },
    //возвращает текущее поле для сортировки, как объект. нужно для отображения в интерфейсе поля, по которому идет сортировка
    getSortField() {
      for (let field of this.config.sortFields) {
        if (field.path === this.sortField) {
          return field;
        }
      }
    },
    async changeKey() {
      await projectKeyHolderNew.resetKey();
      await this.reload();
      await this.finStatsUpdate();
      await this.projectsSumsUpdate();
    },
    reloadOnPopstate() {
      this.applyFiltersFromUrl();
      this.reload();
    }
  },
  async beforeMount() {
    this.applyFiltersFromUrl();

    await this.loadData(
      '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
      'offerExperts',
      true
    );
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        e.code = e.id;
        e.title = e.fullName;
        return e;
      });
    };
    this.offerExperts = getFullName(this.offerExperts);
    this.config.filters.find((e) => {
      return e.path === 'offerExpert.id';
    }).dict = this.offerExperts;
    this.sortField = this.config.defaultSortField;
    
    await this.loadPage();

    this.projectWenc = await wGet();
    this.projectWInfo = await wDec(this.projectWenc);
    this.projectWenc23 = await wGet(2023);
    this.projectWInfo23 = await wDec(this.projectWenc23);
    this.projectWInfo = [...this.projectWInfo, ...this.projectWInfo23];
    
    // Проводки в объект
    this.finStatsUpdate();
    this.projectsSumsUpdate();
    // this.wD = {};
    // if (this.entries.length > 0) {
    //   this.entries.forEach((item) => {
    //     // this.projectsSums.contractSums.plan += item.id;
    //     console.log('item.id', item);
    //     this.wD[item.id] = { sum: {}, data: [] };
    //     this.projectWInfo.forEach((wItem) => {
    //       if (wItem.data.project && wItem.data.project.id == item.id) {
    //         this.wD[item.id].data.push(wItem);
    //       }
    //     });
    //     if (this.wD[item.id].data.length > 0) {
    //       this.wD[item.id].data.forEach((itemW) => {
    //         // TODO внедрить расчеты фин показателей
    //         // console.log('up', this.wD[item.id]);
    //         this.wD[item.id].sum = finPassport(itemW, this.wD[item.id].sum);
    //       });
    //       this.wD[item.id].sum = calculatePassport(this.wD[item.id].sum);
    //     }
    //   });
    // }

    // console.log('wD', this.wD);
    // // this.projectsSumms.summ = 2;
    // if (Object.values(this.wD).length > 0) {
    //   this.projectsSums = projectsSumsReset(this.projectsSums);
    //   console.log('wD length', Object.values(this.wD));
    //   Object.values(this.wD).forEach((value) => {
    //     if (value.data.length > 0) {
    //       this.projectsSums.contractSums.plan += +value.sum.contractSum.plan;
    //       this.projectsSums.contractSums.fact += +value.sum.contractSum.fact;
    //       this.projectsSums.viContractSums.plan +=
    //         +value.sum.viContractSum.plan;
    //       this.projectsSums.viContractSums.fact +=
    //         +value.sum.viContractSum.fact;
    //       this.projectsSums.hiContractSums.plan +=
    //         +value.sum.hiContractSum.plan;
    //       this.projectsSums.hiContractSums.fact +=
    //         +value.sum.hiContractSum.fact;
    //       this.projectsSums.priProjectProfitSums.plan +=
    //         +value.sum.priProjectProfit.plan;
    //       this.projectsSums.priProjectProfitSums.fact +=
    //         +value.sum.priProjectProfit.fact;
    //     }
    //   });
    // }
    this.apiLoaded = true;
    window.addEventListener('popstate', this.reloadOnPopstate);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.reloadOnPopstate);
  },
};
</script>

<style scoped>
  .text-block {
    margin: 10px 0px;
  }
</style>
